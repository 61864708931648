import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import axios from "axios";
import {
  Alert,
} from 'antd';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr /> with us.</>,
  description = "Don't hesitate to reach out – we are here to assist you with any inquiries and to provide the support you need to achieve your business goals. Contact us today to learn more about how we can help you succeed.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [msg, setMsg] = useState("")

  const validateForm = () => {
    const errors = {};
    if (!email) errors.email = 'Email is required';
    if (!name) errors.name = 'Full Name is required';
    if (!subject) errors.subject = 'Subject is required';
    if (!message) errors.message = 'Message is required';
    return errors;
  };

  const handleSubmit = async (event) => {
    setErrors({})
    event.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }


    const formData = {
      email,
      name,
      subject,
      message
    };

    console.log(formData)
    setMsg("Registered successfully. Will get back to you soon")


    // try {
    //   const response = await axios.post('your-api-endpoint', formData);
    //   console.log('Form submitted successfully:', response.data);
    // } catch (error) {
    //   console.error('Error submitting form:', error);
    // }

    setTimeout(() => {
      setMsg("")
      setEmail('');
      setName('');
      setSubject('');
      setMessage('');
    }, 4000)
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit}>
              <Input type="email" name="email" placeholder="Your Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
              <Input type="text" name="name" placeholder="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
              <Input type="text" name="subject" placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              {errors.subject && <p style={{ color: 'red' }}>{errors.subject}</p>}
              <Textarea name="message" placeholder="Your Message Here"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              {errors.message && <p style={{ color: 'red' }}>{errors.message}</p>}


              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>

            {msg && <Form>
              <Alert
                message={
                  msg
                }
                type={'success'}
              />
            </Form>}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
