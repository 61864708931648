import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import logo from "images/logo.svg";

import {
  Button,
  Alert,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Typography,
  Select,
  TreeSelect,
  Calendar,
  TimePicker
} from 'antd';

import moment from "moment";
import axios from "axios";

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);


const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;

const { Title } = Typography;

export default ({
  logoLinkUrl = "#",
  headingText = "Schedule a Call",
}) => {

  const [consultantOptionData, setConsultantOptionData] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('https://api.callclub.in/api/consultant-category');
      if (response.data.status) {
        const categories = response.data.data.map((category) => ({
          value: category.id,
          label: category.name,
        }));
        setConsultantOptionData(categories);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };

  const validatePhoneNumber = (value) => {
    const phoneNumberRegex = /^[+]?[0-9]{0,15}$/;
    return phoneNumberRegex.test(value);
  };

  const onFinish = (values) => {
    console.log('Received values of form: ', values);

    const data = {
      name: 'Guru ji',
      amount: 1,
      number: '7275943351',
      MUID: "MUID" + Date.now(),
      transactionId: 'T' + Date.now(),
    }
    handlePhonePePayment(data)
  };


  const onChangeTime = (time, timeString) => {
    console.log(time, timeString);
  };

  const handlePhonePePayment = async (data) => {
    // e.preventDefault();

    let res = await axios.post('https://api.callclub.in/payment-request-order/phonePe', { ...data }).then(res => {

      console.log(res)
      if (res.data && res.data.data.instrumentResponse.redirectInfo.url) {
        window.location.href = res.data.data.instrumentResponse.redirectInfo.url;
      }
    })
      .catch(error => {
        console.error(error);
      });

  }


  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <Heading>{headingText}</Heading>
            </LogoLink>
            <MainContent>
              <Form
                variant="filled"
                layout="vertical"
                onFinish={onFinish}
              >

                <Form.Item
                  label="Consulting Type"
                  name="consultingType"
                  rules={[
                    {
                      required: true,
                      message: 'Please input!',
                    },
                  ]}
                >
                  <Select
                    options={consultantOptionData}
                  />
                </Form.Item>


                <Form.Item
                  label="Consulting Date"
                  name="date"
                >
                  <Calendar
                    disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
                    fullscreen={false} onPanelChange={onPanelChange} />
                </Form.Item>

                <Form.Item
                  label="Consulting Time"
                  name="time"
                >
                  <TimePicker
                    variant={'outlined'}
                    use12Hours format="h:mm a" onChange={onChangeTime} />
                </Form.Item>

                <Form.Item
                  label="Contact Number"
                  name="contactNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Please input contact number',
                    },
                    {
                      validator: (_, value) =>
                        validatePhoneNumber(value)
                          ? Promise.resolve()
                          : Promise.reject(new Error('Invalid phone number!')),
                    },

                  ]}
                >
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                    stringMode={true}
                    size="10"
                  />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: 'Please input!',
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>

                <Form.Item>
                  <Alert
                    message={
                      <>
                        <Title level={4}>100.00 INR (including Tax)</Title>
                        <Title level={4}>60 min duration</Title>
                      </>
                    }
                    type="success"
                  />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 10,
                    span: 16,
                  }}
                >
                  <Button htmlType="submit">
                    Pay Now
                  </Button>
                </Form.Item>
              </Form>

            </MainContent>
          </MainContainer>

        </Content>
      </Container>
    </AnimationRevealPage>
  )
}