import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: July 21, 2023</p>

            <p>
              This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
              information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </p>

            <p>
              We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
              collection and use of information in accordance with this Privacy Policy.
            </p>

            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>
              The words of which the initial letter is capitalized have meanings defined under the following conditions.
            </p>
            <p>
              The following definitions shall have the same meaning regardless of whether they appear in singular or in
              plural.
            </p>

            <h2>Definitions</h2>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                <p>
                  <strong>You</strong> means the individual accessing or using the Service, or the company, or other
                  legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                </p>
              </li>
              <li>
                <p>
                  <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                  refers to Guru ji InfoSolution.
                </p>
              </li>
              <li>
                <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control
                with a party, where "control" means ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other managing authority.
              </li>
              <li>
                <strong>Account</strong> means a unique account created for You to access our Service or parts of our
                Service.
              </li>
              <li>
                <strong>Website</strong> refers to Guru ji InfoSolution, accessible from https://gurujiinfosolution.com
              </li>{" "}
              <li>
                <strong>Service</strong> refers to the Website.
              </li>

              <li>
                <p>
                  <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf
                  of the Company. It refers to third-party companies or individuals employed by the Company to
                  facilitate the Service, to provide the Service on behalf of the Company, to perform services related
                  to the Service or to assist the Company in analyzing how the Service is used.
                </p>
              </li>
              <li>
                <strong>Third-party Social Media Service</strong> refers to any website or any social network website
                through which a User can log in or create an account to use the Service.
              </li>
              <li>
                <p>
                  <strong>Personal Data</strong> is any information that relates to an identified or identifiable
                  individual.
                </p>
              </li>
              <li>
                <strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other
                device by a website, containing the details of Your browsing history on that website among its many
                uses.
              </li>{" "}
              <li>
                <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the
                Service or from the Service infrastructure itself (for example, the duration of a page visit).
              </li>
            </ul>

            <h2>1. ELIGIBILITY</h2>

            <p>Guru ji InfoSolution does not knowingly collect information from individuals under the age of 18. Our website or posts may contain links to third-party websites with different privacy practices. We do not control these websites and are not responsible for their privacy policies. We encourage users to review the privacy policies of any websites they visit.</p>


            <h2>2.Personal Data</h2>
            <p>
              While using Our Service, We may ask You to provide Us with certain personally identifiable information
              that can be used to contact or identify You. Personally identifiable information may include, but is not
              limited to:
            </p>
            <ul>
              <li>Email address</li> <li>First name and last name</li> <li>Phone number</li>{" "}
              <li>Address, State, Province, ZIP/Postal code, City</li> <li>Usage Data</li>
            </ul>

            <h2>3. DATA WE COLLECT</h2>

            <p><strong>General Information:</strong> Guru ji InfoSolution collects Personal Information from users and consultants, including names, phone numbers, and email addresses, among other details. By providing this information to us, you consent to its collection, use, and disclosure as permitted by applicable privacy laws. We may also collect your geo-location information through our website, mobile application, or other online services.</p>

            <h2>4. HOW WE USE YOUR INFORMATION</h2>

            <p>We use your personal data based on lawful grounds, such as fulfilling contracts with you, obtaining your consent (e.g., email subscriptions), or pursuing our legitimate interests. Specifically, we use your data for:</p>

            <ul>
              <li>Providing and managing access to our Service</li>
              <li>Customizing and enhancing your user experience</li>
              <li>Responding to your inquiries</li>
              <li>Conducting market research</li>
              <li>Analyzing Service usage to improve our offerings and user experience</li>
            </ul>

            <h2>5. PROTECTING VISITOR INFORMATION</h2>

            <p>Our mobile application undergoes regular security scans for vulnerabilities to ensure a safe browsing experience. We implement various security measures to protect your personal information.</p>

            <h2>6. USE OF COOKIES</h2>

            <p>Yes, we use cookies to enhance user experience and collect data on Service traffic and interactions. Cookies are small files stored on your device that allow us to recognize your browser and remember preferences. You can manage cookie settings through your browser; however, disabling cookies may affect certain Service functionalities.</p>

            <h2>7. THIRD PARTY LINKS</h2>

            <p>We may occasionally feature third-party products or services on our website. These third-party sites have separate privacy policies, and we assume no responsibility or liability for their content or activities. We strive to maintain the integrity of our Service and welcome feedback regarding linked sites.</p>

            <h2>8. SHARING YOUR INFORMATION</h2>

            <p>We may share personal information with:</p>

            <ul>
              <li>Service providers: Individuals or entities performing services on our behalf, including customer support, marketing, business analysis, and technical support. These providers have access to personal information necessary to perform their duties but are prohibited from using it for other purposes.</li>
              <li>Business partners: Companies with whom we collaborate to offer products, services, or promotions. If you engage in a merchant’s offer or program through our Service, we may share your email address and relevant information with them.</li>
              <li>Legal compliance and safety: We may disclose user information in response to legal requirements, such as court orders or law enforcement requests, or to protect our rights, property, or safety of users or the public.</li>
            </ul>

            <h2>9. PERSONAL INFORMATION SECURITY</h2>

            <p>Guru ji InfoSolution employs appropriate security measures (physical, electronic, and procedural) to protect your personal information from unauthorized access or disclosure. While we strive to secure your data, no system is entirely foolproof. Therefore, we cannot guarantee the absolute security of information transmitted through our Service.</p>

            <p>You agree that we may communicate electronically about security, privacy, and administrative issues, including security breaches. We may post security breach notices on our Service or email you at the address you provided.</p>

            <h2>10. RIGHTS OF THIRD PARTIES</h2>

            <p>This Privacy Policy does not confer rights enforceable by third parties or require disclosure of users’ personal information.</p>

            <h2>11. CHANGES TO THIS PRIVACY POLICY</h2>

            <p>We periodically update this Privacy Policy, and revisions will be reflected in the 'last updated' date at the top. We recommend checking our website for updates on this and other policies.</p>

            <p>
              You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
              are effective when they are posted on this page.
            </p>

            <h2>Contact Us</h2>

            <p>For questions regarding our website and your use of it, please contact us at [info@gurujiinfosolution.com].</p>

            
           
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
