import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h1 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Refund and Cancellation" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Please carefully review the cancellation and refund policy of Guru ji InfoSolution.</p>
            <p>By accessing and using our services through this website, you enter into a legally binding agreement with Guru ji InfoSolution. You confirm that you are at least 18 years old and accept full responsibility for any payments made by you or on your behalf. Additionally, you affirm that the information provided by you is accurate and truthful.</p>

            <h1>1. INFORMATION</h1>
            <p>You agree that the information you provide is accurate and complete to the best of your knowledge. Guru ji InfoSolution will not be liable for any issues arising from false or inaccurate information provided by you.</p>
            <p>If any of the information you provide is incorrect, we will reach out to you via email or phone to obtain the correct details. It is your responsibility to respond promptly. Failure to do so may result in the termination of your access to our services.</p>

            <h1>2. CANCELLATIONS AND REFUNDS</h1>
            <p>You acknowledge that any payments made for consulting services are non-refundable. The terms for cancellations and refunds are governed by the policy outlined here.</p>

            <h3>Payment Refunds</h3>
            <p>Once you have utilized our consultation services, you cannot request a refund. If you have had a conversation with a consultant, no refund will be provided.</p>
            <p>Refunds are not available if the call was not answered directly by the consultant. Guru ji InfoSolution does not control the consultant's availability, and therefore, no refunds will be issued if the consultant fails to answer the call.</p>
            <p>In exceptional cases where technical issues on our end prevent you from connecting with the consultant, and this is confirmed by our team, you will not be charged for the call. Any deducted amount will be credited to your account, and we will process the refund within 4-5 days from the date the issue was reported.</p>
            <p>A 10% fee applies to all refunds and withdrawals. Guru ji InfoSolution reserves the right to alter these charges at its discretion.</p>

            <h1>DISCLAIMER OF LIABILITY</h1>
            <p>Guru ji InfoSolution provides a platform for consultations and is not responsible for any content or actions of the consultants. We do not verify or endorse the consultants or their content. Consultants are independent providers and are not employees or agents of Guru ji InfoSolution.</p>
            <p>You agree not to hold Guru ji InfoSolution liable for any loss or damage arising from reliance on information provided by consultants or any content on our platform. We have no control over the services or information provided by consultants.</p>

            <h1>LEGAL ACTION</h1>
            <p>In cases of incorrect information or misuse of our platform, appropriate legal action will be taken. Guru ji InfoSolution reserves the right to deduct amounts equivalent to a 30-minute consultation fee from future transactions if necessary.</p>

            <p>Your continued use of our services signifies your acceptance of these terms.</p>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
