import React from "react";
import { Button, Result } from 'antd';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import Footer from "components/footers/MiniCenteredFooter.js";


export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Result
        status="success"
        title="Successfully Purchased"
        subTitle="Order number: 2017182818828182881, Successfully placed."
        extra={[
          <Button key="buy">Buy Again</Button>,
        ]}
      />

      <Footer />
    </AnimationRevealPage>
  );
};
