import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h1 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Conditions" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: July 21, 2023</p>

            <p>Please read these terms and conditions carefully before using Our Service.</p>

            <h1>Interpretation and Definitions</h1>
            <h1>Interpretation</h1>
            <p>
              The words of which the initial letter is capitalized have meanings defined under the following conditions.
            </p>
            <p>
              The following definitions shall have the same meaning regardless of whether they appear in singular or in
              plural.
            </p>

            <h1>Definitions</h1>
            <p>For the purposes of these Terms and Conditions:</p>
            <ul>
              <li>
                <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control
                with a party, where "control" means ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other managing authority.
              </li>
              <li>
                <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                refers to Guru ji InfoSolution.
              </li>
              <li>
                <strong>Service</strong> refers to the Website.
              </li>
              <li>
                <strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that
                form the entire agreement between You and the Company regarding the use of the Service.
              </li>
              <li>
                <strong>Third-party Social Media Service</strong> means any services or content (including data,
                information, products or services) provided by a third-party that may be displayed, included or made
                available by the Service.
              </li>
              <li>
                <strong>Website</strong> refers to Guru ji InfoSolution, accessible from https://gurujiinfosolution.com
              </li>
              <li>
                <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal
                entity on behalf of which such individual is accessing or using the Service, as applicable.
              </li>
            </ul>

            <h1>Acknowledgement</h1>
            <p>
              These are the Terms and Conditions governing the use of this Service and the agreement that operates
              between You and the Company. These Terms and Conditions set out the rights and obligations of all users
              regarding the use of the Service.
            </p>
            <p>
              Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms
              and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the
              Service.
            </p>
            <p>
              By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree
              with any part of these Terms and Conditions then You may not access the Service.
            </p>
            <p>
              You represent that you are over the age of 18. The Company does not permit those under 18 to use the
              Service.
            </p>
            <p>
              Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the
              Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection,
              use and disclosure of Your personal information when You use the Application or the Website and tells You
              about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before
              using Our Service.
            </p>

            <h1>Links to Other Websites</h1>
            <p>
              Our Service may contain links to third-party web sites or services that are not owned or controlled by the
              Company.
            </p>
            <p>
              The Company has no control over, and assumes no responsibility for, the content, privacy policies, or
              practices of any third party web sites or services. You further acknowledge and agree that the Company
              shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be
              caused by or in connection with the use of or reliance on any such content, goods or services available on
              or through any such web sites or services.
            </p>
            <p>
              We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites
              or services that You visit.
            </p>

            <h1>How the Site, Application, and Services Function</h1>
            <p>Guru ji InfoSolution serves as a platform that facilitates connections between individuals, enabling them to exchange ideas, seek guidance, or receive consultation. It provides Members/Customers the opportunity to connect with and obtain consultation from their chosen Consultants.</p>

            <p>Our Consultants at Guru ji InfoSolution come from diverse fields, including but not limited to Psychology, Teaching, Astrology, Relationship Counseling, Motivation, Social Media, Business, Sales & Marketing, Funding, Product & Design, Technology, Skills & Management, Industries, Branding, Finance, Stock Trading, Customer Engagement, Strategy, Human Resources, Business Development, Legal, Accounts, Security, Sales & Marketing, Social Media Marketing, Search Engine Optimization, Public Relations, Branding, Publishing, Inbound Marketing, Email Marketing, Content Writing, Advertising, Fitness, Software Development, Mobile Technology, Insurance, Coaching, E-commerce, Education, Sports, Real Estate, Restaurant & Retail, Blogging, among others.</p>

            <p>It is explicitly stated that we do not impose any specific eligibility criteria to classify someone as an expert or Consultant. Consultants present themselves as specialists in their respective fields/categories/professions and assert their expertise in those areas. We do not verify their credentials, degrees, or experience, and thus, we bear no responsibility or liability in this regard.</p>

            <p>Upon downloading the Guru ji InfoSolution mobile application, you will have access to the terms and conditions governing its use. It is your obligation to read, understand, and accept these terms and conditions. Should you disagree with any part of our terms or conditions, you are encouraged to contact us for clarification or choose not to use Guru ji InfoSolution further. For further details, you can reach us at support@gurujinfo.com. By agreeing to and using the Guru ji InfoSolution app, you consent to abide by the terms and conditions.</p>

            {/* <h1>THE SERVICE FOR ANDROID USERS</h1>
            <p>Guru ji InfoSolution offers a Mobile App designed to connect Celebrities, Influencers, and Consultants (hereinafter referred to as “Consultant”) with their fans, followers, users, or other Guru ji InfoSolution app users. Through Guru ji InfoSolution, users can connect with verified and trusted consultants and experts via direct telephone calls. Users can seek advice, receive personalized assistance, or learn something new every day from popular social media personalities worldwide.</p>

            <h3>Our Service to Android Users:</h3>
            <p>Users of smartphones running the Android operating system can download the Guru ji InfoSolution App, available on the Google Play Store. Android users will be able to make Voice Over Internet Protocol (VOIP) calls, including both voice and video calls, at the rates set by each Consultant. Android users will also be able to make public switched telephone network (PSTN) audio calls.</p>

            <h1>THE SERVICE FOR iOS USERS</h1>
            <p>Guru ji InfoSolution offers a Mobile App designed to connect Celebrities, Influencers, and Consultants (hereinafter referred to as “Consultant”) with their fans, followers, users, or other Guru ji InfoSolution app users. Through Guru ji InfoSolution, users can connect with verified and trusted consultants and experts via direct telephone calls. Users can seek advice, receive personalized assistance, or learn something new every day from popular social media personalities worldwide.</p>

            <h3>Our Service to iOS Users:</h3>
            <p>Users of smartphones running the iOS operating system can download the Guru ji InfoSolution App from the Apple Store. iOS users will only be able to make public switched telephone network (PSTN) audio calls. As an iOS user, you acknowledge that internet voice or video calls are not available to you. You also understand that charges will be applied according to rates provided by your Network Service Provider. This does not prevent us from charging rates applicable to the Consultant you have consulted.</p> */}

            <h1>ELIGIBILITY</h1>
            <p>You confirm that you are at least 18 years old to access the website and its services. Use of the Service is prohibited in countries or territories where it is not allowed. By accessing and using the Mobile App and its services, you represent and warrant that you have the authority and capacity to enter into this Agreement and comply with all terms of use of this Agreement.</p>


            <h1>CALL RATES</h1>
            <ul>
              <li>Call Rates are determined on a per-minute basis.</li>
              <li>Once the Call Rate is established by the Consultant, the amount will be deducted from the Customer’s Guru ji InfoSolution app wallet after the consultation. Charges will be applied for each minute based on the Call Rate set by the Consultant.</li>
              <li>If the Customer’s Guru ji InfoSolution wallet does not have the required minimum balance, they will be unable to initiate a call with the Consultant. The Minimum Amount/Balance is defined as the per-minute call rate set by the Consultant for 1 minute of the call.</li>
              <li>Customers are billed for a minimum of 1 minute per call. Additionally, the pulse of a call is 1 minute. The Customer will be charged according to the Consultant’s call rate at the start of every minute. For example, if a call lasts 1 minute and 10 seconds, the Customer will be billed for 2 minutes.</li>
            </ul>

            {/* <h1>WALLET</h1>
            <p>The Guru ji InfoSolution Wallet details are accessible from the App Homepage.</p>
            <p>If the Customer has not deposited any funds into their App Wallet, the balance will show as ₹0.00 (zero).</p>
            <p>If Guru ji InfoSolution decides to provide bonus amounts to Customers/Consultants at its discretion, the wallet balance will reflect the bonus amount.</p> */}

            {/* <h1>PAYMENT/DEPOSIT</h1>
            <p>Customers can "Add/Deposit" money into their App wallet. Once funds are added, they may call the Consultant of their choice.</p>
            <p>To initiate a call with a Consultant, the Customer’s app wallet must have a minimum balance equivalent to at least the charges for 1 minute of the call rate set by the Consultant. If the wallet balance is insufficient for the minimum required for 1 minute of the call, the Customer will be unable to contact the Consultant through the Guru ji InfoSolution platform.</p>
            <p>Deposits into the Guru ji InfoSolution wallet are processed by third-party payment gateways. Guru ji InfoSolution currently uses the Paytm gateway and may use additional or different payment gateways to enhance convenience for our Customers/Consultants. Although Guru ji InfoSolution collaborates with reputable payment gateway providers, it does not control their operations and is not responsible for any loss or damage incurred due to these payment gateways. Customers/Consultants are advised to use these gateways carefully.</p>
            <p>While the Mobile App is provided at no cost, users need to fund their wallet to access certain features. Charges are applied on a per-minute basis for all consultations.</p>
            <p>The applicable rate will be displayed before making a consultation call to any Consultant.</p>
            <p>To fund the wallet, you authorize us to charge the payment information you provide through the Guru ji InfoSolution Mobile App. If we cannot process the payment, we will notify you of the failed transaction. We reserve the right to suspend services or terminate your account if the payment information poses a potential risk.</p>
            <p>All payments made through the App are exclusive of applicable taxes, and you are responsible for any taxes arising from your use of the service.</p> */}

            {/* <h1>WITHDRAWAL</h1>
            <p>A Customer or Consultant can request a withdrawal from the Guru ji InfoSolution wallet balance if the amount is ₹200 (Two hundred rupees) or more. The minimum withdrawal limit is subject to change without prior notice. For updated limits, contact Guru ji InfoSolution customer support via the "Get Help" section on the app.</p>
            <p>After a withdrawal request, the funds are transferred to the Customer’s or Consultant’s Paytm or Bank Account. If no preference is provided, the amount will be credited to the registered Paytm number on the Guru ji InfoSolution app.</p>
            <p>Upon requesting a withdrawal, the Customer/Consultant must provide their Paytm or bank account details. If not provided, funds will be transferred to the registered Paytm account. Contact Guru ji InfoSolution customer service via the "Get Help" section for queries.</p>
            <p>If no preference is mentioned, the amount will be processed to the registered Paytm number. Guru ji InfoSolution is not responsible for any loss or damages if the funds are not received. It is advised to provide accurate information.</p>
            <p>Withdrawal requests are processed within a minimum of 48 hours or 2 working days, excluding weekends and public holidays.</p>
            <p>Withdrawals may be withheld if suspicious activity or other concerns are identified. For any questions, contact customer support via the "Get Help" section.</p>
            <p>Guru ji InfoSolution is not responsible for losses during bank or third-party payment transfers. We will attempt to resolve issues by contacting service providers and may require Customers/Consultants to resolve issues with their service providers.</p> */}

            <h1>CHARGEBACK</h1>
            <p>The Customer agrees not to initiate chargebacks as they are not applicable to Guru ji InfoSolution’s services. Guru ji InfoSolution is a service platform provider and does not sell products. Chargebacks are not allowed for services used.</p>

            <h1>LOCATION</h1>
            <p>The Guru ji InfoSolution platform does not require Customers or Consultants to provide their location details. Consultants have the option to allow us to determine and display their location for Customer visibility on the platform and for record-keeping purposes only. This information is solely shared with Customers on the Guru ji InfoSolution platform and is not disclosed to any other parties.</p>

            <h1>PAYMENT REFUND</h1>
            <p>Once the consultation services provided by Guru ji InfoSolution have been utilized, Customers are not eligible for refunds. If a Customer has engaged in a consultation with any Consultant, they cannot request a payment refund.</p>
            <p>Refunds will not be granted if the call was not directly received by the Consultant. Guru ji InfoSolution is not responsible for situations where a Consultant does not answer a call as scheduled. The platform does not control the Consultant's availability, and no refunds will be issued if the Consultant does not answer directly.</p>
            <p>In rare cases where a Customer is unable to connect with a Consultant due to technical issues on Guru ji InfoSolution's end, and this is confirmed by our support team, the Customer will not be charged. If a balance has been deducted, it will be credited back to the Customer’s account. Refunds will be processed promptly and are generally completed within 4-5 days from when the issue was reported.</p>
            <p>A standard fee of 10% applies to all refund and withdrawal requests. Guru ji InfoSolution reserves the right to modify these fees at its discretion.</p>
            <p>By using our platform, you acknowledge and agree that Guru ji InfoSolution's role is limited to providing the platform itself. All responsibilities related to compliance with laws, contractual obligations, and the accuracy of information provided by Consultants are not our responsibility. Guru ji InfoSolution shall not be liable to you or any other party for any claims related to these aspects.</p>
            <p>Guru ji InfoSolution does not edit, modify, filter, screen, or endorse any Member Content or communication between Customers and Consultants. As a Consultant, you are responsible for ensuring the accuracy of your information and for your actions and omissions.</p>
            <p>We do not verify the credentials of Consultants. Consultants are independent service providers using the platform to offer their services, and Guru ji InfoSolution is not liable for any damages resulting from reliance on information provided by Consultants or Member Content.</p>
            <p>You agree not to hold Guru ji InfoSolution liable for any losses incurred from using the services facilitated by our platform. All services and information provided through Guru ji InfoSolution come from Consultants, and Guru ji InfoSolution does not control or influence these services. Any loss, damage, or harm resulting from the use of our platform will not result in claims or damages against us.</p>
            <p>Appropriate actions will be taken against Customers who provide incorrect information or attempt to exploit the platform. Legal action may be initiated for inappropriate behavior towards Guru ji InfoSolution or its Consultants. We also reserve the right to deduct amounts equivalent to a 30-minute consultation from future transactions if necessary.</p>

            <h1>TAXES</h1>
            <p>You are responsible for determining your tax reporting requirements with your tax advisors. Guru ji InfoSolution does not offer tax-related advice to Members of our platform.</p>
            <p>All taxes, including any goods and services or value-added taxes, applicable to the services provided are your responsibility based on the jurisdiction.</p>
            <p>You must comply with income tax obligations in your jurisdiction.</p>
            <p>We maintain a ledger for each Consultant, available upon request. While we assist in ensuring accurate transactions, we do not guarantee no charges if calls are not connected. Any deducted amount from your Guru ji InfoSolution wallet will be reflected in your ledger balance.</p>
            <p>The role of Guru ji InfoSolution is limited to providing the platform and related services, including arranging consultations, scheduling, call management, payment integration, and facilitation.</p>

            <h1>Limits & Fraud Prevention</h1>
            <p>Any fraudulent, unfair, or unethical use of the Guru ji InfoSolution platform will result in immediate suspension of services. Guru ji InfoSolution reserves the right to block, suspend, or revoke the accounts of users engaged in unfair practices and may withhold any payments due to such users.</p>
            <p>Guru ji InfoSolution may suspend Website/Mobile Application Services to you at any time, for reasons including, but not limited to, the following:</p>
            <ul>
              <li>Suspected violations of rules, regulations, notifications, and directions issued by relevant authorities or breaches of these terms and conditions.</li>
              <li>Suspected discrepancies in the details, documentation, or KYC (Know Your Customer) enrollment information provided by you.</li>
              <li>To prevent potential fraud, damage, willful destruction, abusive language, or threats to national security.</li>
              <li>Due to technical issues such as failure, modification, upgrade, maintenance, or other emergency situations.</li>
              <li>Transmission deficiencies caused by topographical or geographical limitations.</li>
              <li>If your registered mobile connection with Guru ji InfoSolution is no longer operational or under your control.</li>
              <li>If we reasonably determine that suspension is necessary.</li>
              <li>We reserve the right to suspend any withdrawal requests if the source of funds is suspected to be fraudulent.</li>
              <li>Guru ji InfoSolution may use any balance in your wallet to recover funds for transactions processed as per your request.</li>
            </ul>
            <p>Account expiry and balance forfeiture will apply if there is no activity on the Guru ji InfoSolution platform for a period of three months from the last activity. Guru ji InfoSolution reserves the right to forfeit such amounts.</p>
            <p>Transaction charges will be in accordance with applicable guidelines from relevant authorities.</p>

            <h1>Content Misuse</h1>
            <p>You are prohibited from posting false, inaccurate, misleading, defamatory, or offensive content (including personal information). Guru ji InfoSolution is not responsible for the content of advertisements posted by users. We will take strict action against any content reported to us that is obscene or offensive.</p>

            <h1>Using Guru ji InfoSolution</h1>
            <p>When using the Guru ji InfoSolution platform, you agree to:</p>
            <ul>
              <li>Provide true, accurate, and complete information during registration and update it as necessary.</li>
              <li>Log out of your account at the end of each session to ensure your online security.</li>
              <li>Not copy, modify, rent, lease, loan, sell, or otherwise transfer any rights to the technology or software of Guru ji InfoSolution.</li>
              <li>Not use the Services to engage in any offensive, obscene, indecent, or unlawful activities.</li>
              <li>Not attempt to hack, transmit malicious files, or otherwise damage the site or application.</li>
              <li>Not advertise or sell goods or services without prior permission from Guru ji InfoSolution.</li>
              <li>Not distribute spam, unsolicited advertising, or chain letters.</li>
              <li>Not distribute viruses or other harmful technologies.</li>
              <li>Not falsify or delete any author attributions or proprietary notices.</li>
              <li>Not attempt to reverse engineer or decompile any software used by Guru ji InfoSolution.</li>
              <li>Not copy or distribute any content or rights from the website or application.</li>
              <li>Not collect or store personal information about other users.</li>
              <li>Not impersonate any person or entity, including representatives of Guru ji InfoSolution.</li>
              <li>Not forge headers or manipulate identifiers to disguise the origin of content.</li>
              <li>Not impose an unreasonable load on our infrastructure or network.</li>
              <li>Not engage in illegal activities.</li>
              <li>Not access the site or application through unauthorized means.</li>
              <li>Not use automated means to access or copy parts of the site without prior approval.</li>
            </ul>

            <h1>KYC Compliance</h1>
            <p>"KYC" stands for Know Your Customer and refers to various norms issued by relevant authorities.</p>
            <p>Guru ji InfoSolution is required to collect and verify personal identification details before delivering services. We reserve the right to take necessary steps to ensure compliance with KYC requirements.</p>
            <p>We may discontinue services if discrepancies are found in the information provided by you. Information provided will be retained and used in accordance with applicable laws.</p>
            <p>In case of mismatched details, Guru ji InfoSolution has the right to forfeit the balance in your account or halt transfers to other accounts.</p>
            <p>These limitations may be modified at the discretion of Guru ji InfoSolution in accordance with relevant guidelines.</p>

            <h1>Confidential Information</h1>
            <p>Do not share any confidential or proprietary information with Guru ji InfoSolution. While we take measures to protect personally identifiable information as outlined in our Privacy Policy, any other information you send to us will be treated as non-confidential.</p>

            <h1>Logos and Property</h1>
            <p>The Guru ji InfoSolution name, logos, and other intellectual property are owned by Guru ji InfoSolution. Unauthorized use of these marks is prohibited without written consent. Requests for authorization should be directed to <a href="mailto:support@gurujinfo.com">support@gurujinfo.com</a>.</p>

            <h1>Calls Recording</h1>
            <p>We reserve the right to record calls made through the Guru ji InfoSolution platform for quality control and internal purposes. By using our platform, you agree to this recording and its usage for professional purposes.</p>

            <h1>Services Not Offered by Guru ji InfoSolution</h1>
            <p>We do not offer services related to:</p>
            <ul>
              <li>Gambling</li>
              <li>Remittance services</li>
              <li>Obscene, indecent, or illegal content</li>
              <li>False or misleading statements</li>
              <li>Content that infringes on intellectual property rights or privacy</li>
              <li>Software viruses or malicious code</li>
              <li>Impersonation or unauthorized data collection</li>
              <li>Activities prohibited by law</li>
            </ul>

            <section>
              <h1>Changes to Terms of Use</h1>
              <p>We retain the right to alter or replace any part of this Agreement at our sole discretion. It is your duty to review these terms periodically for any updates. Your continued use of or access to our website following the posting of changes signifies your acceptance of these modifications. Additionally, we may introduce new services or features, which will also be governed by these terms.</p>
            </section>

            <section>
              <h1>Content You Post</h1>
              <p>a. By uploading content to our website or as part of our services, you grant Guru ji InfoSolution, its affiliates, licensees, contractors, and agents an irrevocable, perpetual, non-exclusive, fully paid-up, global license to (i) use, copy, store, display, reproduce, adapt, modify, and distribute your content, (ii) create derivative works from it, and (iii) authorize sublicenses in any existing or future media. You warrant that your content does not infringe any third-party rights.</p>

              <p>b. The following types of content are strictly prohibited on our platform:</p>
              <ul>
                <li>Content that promotes discrimination, hatred, or violence against individuals or groups;</li>
                <li>Content that harasses or intimidates others;</li>
                <li>Content intended to defraud or solicit money from users;</li>
                <li>Spam, including unsolicited messages and phishing;</li>
                <li>False, misleading, or illegal content;</li>
                <li>Unauthorized copies of copyrighted material or illegal distribution of pirated content;</li>
                <li>Images or videos of individuals without their permission, or involving minors without consent;</li>
                <li>Restricted access content or hidden pages;</li>
                <li>Content that exploits individuals in illegal or harmful ways, or solicits personal information from minors;</li>
                <li>Content providing instructions on illegal activities or spreading computer viruses;</li>
                <li>Malware or harmful code;</li>
                <li>Impersonation or misrepresentation of any entity;</li>
                <li>Disclosure of confidential or proprietary information without authorization;</li>
                <li>Disruptive behavior affecting other users’ experience;</li>
                <li>Soliciting personal information or passwords unlawfully;</li>
                <li>Commercial promotions or advertisements without prior written consent.</li>
              </ul>
              <p>Guru ji InfoSolution reserves the right to investigate and take appropriate action against any violations, including removing offending content and suspending accounts.</p>
            </section>

            <section>
              <h1>Disclaimers</h1>
              <p>a. You acknowledge that Guru ji InfoSolution and its partners are not liable for any damages, including personal injury or death, resulting from (i) inaccuracies or incompleteness of the content on our site, (ii) issues with the timeliness or storage of content, (iii) any user’s conduct, (iv) errors or disruptions in communication, or (v) technical issues affecting service.</p>

              <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, OUR SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT OUR SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE.</p>
            </section>

            <section>
              <h1>Content Disclaimer</h1>
              <p>Guru ji InfoSolution provides content created by various third parties. While efforts are made to ensure accuracy, we do not control or guarantee the authenticity, completeness, or legality of this content. We are not liable for any damages arising from the use of such content or errors therein.</p>

              <p>All rights to the content and trademarks on our site are owned by Guru ji InfoSolution. Unauthorized use of this content is prohibited. External links provided are for convenience and do not imply endorsement. We are not responsible for the content or safety of external sites linked from our platform.</p>
            </section>

            <section>
              <h1>Warranty Disclaimer</h1>
              <p>Guru ji InfoSolution does not vet advertisers or service providers. It is your responsibility to evaluate these entities before engaging in any transactions. We do not guarantee the quality or legality of products or services offered through our platform.</p>

              <p>We make no representations about the accuracy or reliability of any information or materials on our platform. All transactions are at your own risk. We disclaim all warranties, including implied warranties of merchantability and fitness for a particular purpose.</p>
            </section>


            <h1>Liability Limitations</h1>
            <p>TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER Guru ji InfoSolution NOR ITS AFFILIATES, PARTNERS, OR SERVICE PROVIDERS SHALL BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR TOTAL LIABILITY TO YOU WILL BE LIMITED TO THE AMOUNT PAID BY YOU FOR OUR SERVICES DURING THE TERM OF YOUR ACCOUNT. CLAIMS MUST BE FILED WITHIN ONE YEAR OF THE EVENT CAUSING THE CLAIM.</p>

            <p>UNDER NO CIRCUMSTANCES SHALL Guru ji InfoSolution BE LIABLE TO ANY USER FOR ANY DAMAGES ARISING FROM THE USE OR MISUSE OF THE PLATFORMS, INCLUDING BUT NOT LIMITED TO DIRECT, SPECIAL, PUNITIVE, INDIRECT, CONSEQUENTIAL, OR INCIDENTAL DAMAGES. THIS INCLUDES DAMAGES FOR LOSS OF PROFITS, REVENUE, USE, OR DATA, WHETHER BASED IN WARRANTY, CONTRACT, INTELLECTUAL PROPERTY INFRINGEMENT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY. EVEN IF Guru ji InfoSolution HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES ARISING FROM THE USE OR INABILITY TO USE THE PLATFORMS, MATERIALS, OR THE INTERNET IN GENERAL, THE LIABILITY WILL BE LIMITED AS PER APPLICABLE LAW. Guru ji InfoSolution MAKES NO WARRANTIES REGARDING THE ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY INFORMATION, PRODUCT, OR PROCESS DISCLOSED ON THE PLATFORMS.</p>

            <h1>Third Party Sites</h1>
            <p>Your interactions or transactions with third parties found through the Guru ji InfoSolution Platforms, including advertisers or service providers, are solely between you and those parties. Guru ji InfoSolution DISCLAIMS ANY LIABILITY FOR LOSS OR RESULTING FROM SUCH DEALINGS. We are not responsible for the accuracy, legality, or any other aspect of the content or services available from these third-party sites. You acknowledge that these linked sites have their own terms of use and privacy policies, which may differ from those of Guru ji InfoSolution.</p>

            <h1>Monitoring of Materials Transmitted by You</h1>
            <p>Guru ji InfoSolution may periodically update or change the Platforms. We are not obligated to monitor materials on the Platforms but reserve the right to do so to comply with legal requirements or protect our interests and users. We may modify or remove any content that we deem inappropriate or in violation of these Terms and Conditions.</p>

            <h1>Indemnification</h1>
            <p>You agree to indemnify and hold harmless Guru ji InfoSolution, including its affiliates, officers, directors, agents, and employees, from any claims or demands arising out of your breach of these Terms, violation of any laws, or infringement of any third-party rights. This includes claims related to intellectual property rights, privacy, or any other legal claims.</p>

            <h1>Miscellaneous</h1>
            <p>These Terms are governed by the laws of India. Any disputes will be resolved in the courts located in Delhi, India. If any provision of these Terms is found to be unenforceable, the remaining provisions will remain in effect. This agreement supersedes all previous agreements and can only be modified in writing by Guru ji InfoSolution. We reserve the right to investigate complaints or violations and take appropriate actions.</p>

            <h1>Notices</h1>
            <p>All notices must be in writing and will be considered given if mailed by certified mail, delivered by overnight courier, sent by facsimile, or electronic mail. Notices to Guru ji InfoSolution should be sent to the email address: support@gurujinfo.com.</p>

            <h1>Force Majeure</h1>
            <p>Guru ji InfoSolution will not be liable for any delay or failure to perform due to circumstances beyond our control, including but not limited to natural disasters, governmental actions, or network failures. Payment obligations will continue during any force majeure event.</p>

            <h1>Telephone Conversations</h1>
            <p>All telephone conversations between you and Guru ji InfoSolution regarding your advertising may be recorded with your consent.</p>

            <h1>Arbitration</h1>
            <p>Any disputes arising from these Terms will be resolved through binding arbitration under the Indian Arbitration and Conciliation Act, 1996. The arbitration proceedings will be conducted in English, with a sole arbitrator appointed by Guru ji InfoSolution. The venue for arbitration will be Delhi, India.</p>

            <h1>Terms Not Covered</h1>
            <p>Any terms not specifically covered in this agreement will be determined based on market practices or similar agreements.</p>

            <h1>Complete Agreement</h1>
            <p>This agreement constitutes the entire understanding between you and Guru ji InfoSolution regarding the use of our Platforms. It supersedes all prior agreements and may be modified only in writing or through revised terms posted on our website.</p>

            <h1>Arbitration and Governing Law</h1>
            <p>The exclusive means of resolving disputes related to this agreement or the service will be binding arbitration. You may not initiate or participate in class actions. If the arbitration agreement is unenforceable, disputes may be resolved in the courts of India, to which you consent to jurisdiction.</p>

            <h1>Acknowledgement & Feedback</h1>
            <p>You acknowledge that you have read and understood these Terms and have the authority to accept them. </p>


            <h1>Contact Us</h1>
            <p>If you have any questions about these Terms and Conditions, You can contact us:</p>

            <ul>
              <li>By email: info@gurujiinfosolution.com</li>
              <li>By phone number: +91 93366 52575</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
