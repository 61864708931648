import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import axios from "axios";
import {
  Alert,
} from 'antd';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.div`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`

export default ({
  subheading = "Unsubscribe Us",
  heading = <>Enter Mobile no to <span tw="text-primary-500">Remove account</span><wbr /></>,
  description = "",
  submitButtonText = "Remove Me",
  textOnLeft = true,
}) => {
  const [contact, setContact] = useState("")
  const [msg, setMsg] = useState("")
  const [msgType, setMsgType] = useState("")

  const onConnectMe = () => {
    if (contact.length == 10) {
      setMsgType("success")
      setMsg("Remove successfully.")

    } else {
      setMsgType("error")
      setMsg("Contact number not valid")
    }

    setTimeout(() => {
      setMsg("")
      setContact("")
    }, 3000)
  }

  const removeAccount = async () => {
    try {
      const response = await axios.post('https://api.callclub.in/api/users/remove-account', {
        contact: contact
      });
      const { message } = response.data
      setMsgType("success")
      setMsg(message)
    } catch (error) {
      const { message } = error.response.data
      setMsgType("error")
      setMsg(message)
    }

    setTimeout(() => {
      setMsgType("")
      setMsg("")
      setContact("")
    }, 3000)
  };


  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Form>
              <Input value={contact} onChange={(event) => { setContact(event.target.value) }} type="number" name="phone" placeholder="Your Contact Number" />
              <SubmitButton onClick={removeAccount} >{submitButtonText}</SubmitButton>

            </Form>
            {msg && <Form>
              <Alert
                message={
                  msg
                }
                type={msgType}
              />
            </Form>}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
